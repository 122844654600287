import img58 from './renders/58.png'
import img58neo from './renders/58eo.png'
import img70neo from './renders/70neo.png'
import img82 from './renders/82.png'


 const profilesArr = [
    {
        name: '58',
        img: img58,
        profileWeight: '58мм',
        chambersCount: '3',
        typeByWallThickness: 'B',
        glazingWidth: 'до 32мм',
        distanceOfTheHardwareGroove: '13мм',
        frameHeight: '63мм',
        sashHeight: '57мм',
    },
    {
        name: '58 NEO',
        img: img58neo,
        profileWeight: '58мм',
        chambersCount: '3',
        typeByWallThickness: 'B',
        glazingWidth: 'до 32мм',
        distanceOfTheHardwareGroove: '13мм',
        frameHeight: '63мм',
        sashHeight: '57мм',
    },
    {
        name: '70NEO',
        img: img70neo,
        profileWeight: '70мм',
        chambersCount: '5',
        typeByWallThickness: 'B',
        glazingWidth: 'до 40мм',
        distanceOfTheHardwareGroove: '13мм',
        frameHeight: '63мм',
        sashHeight: '77мм',
    },
    {
        name: '82',
        img: img82,
        profileWeight: '82мм',
        chambersCount: '5',
        typeByWallThickness: 'B',
        glazingWidth: 'до 40мм',
        distanceOfTheHardwareGroove: '13мм',
        frameHeight: '70мм',
        sashHeight: '69.5мм',
    }
]

export default profilesArr
